import React from 'react';
import {useTranslation} from "react-i18next";
import {IconContext} from "react-icons";
import { CiMail } from "react-icons/ci";
import {BsTelephone} from "react-icons/bs";
import { RiMapPinLine } from "react-icons/ri";
import image from "../assets/Ships logo PNG.png";

const Contact = () => {

    const {t} = useTranslation("common");

    return (
        <div id="contact" className="dark:bg-gray-900 mt-4 text-white min-h-[45vh]">
            <div className="px-4 sm:px-6 lg:px-8" style={{paddingBottom: '96px', paddingTop: '96px'}}>
                <div className="mt-5 flex flex-col md:flex-row justify-around items-center">
                    <dl className="flex justify-center items-center flex-col text-center mb-6 md:mb-0">
                        <a href="/" className="flex items-center">
                            <img src={image} className="h-32 object-contain" alt="Flowbite Logo" />
                        </a>
                        <div className="mt-4">
                            <h1 className="text-white text-xl sm:text-2xl font-bold max-w-xs leading-tight">
                                {t('slogan.text-1')}
                            </h1>
                        </div>
                    </dl>
                    <dl className="space-y-8 md:space-y-6">
                        <div>
                            <p className="text-2xl font-extrabold text-white">
                                {t('contact.title')}
                            </p>
                        </div>
                        <div className="flex items-center">
                            <IconContext.Provider value={{ size: "1.5em" }}>
                                <BsTelephone />
                            </IconContext.Provider>
                            <dd className="ml-2 text-white text-md">
                                (+99455) 303-11-66
                            </dd>
                        </div>
                        <div className="flex items-center">
                            <IconContext.Provider value={{ size: "1.5em" }}>
                                <CiMail />
                            </IconContext.Provider>
                            <dd className="ml-2 text-white text-md">
                                info@ships-engineering.az
                            </dd>
                        </div>
                        <div className="flex items-center">
                            <IconContext.Provider value={{ size: "1.5em" }}>
                                <RiMapPinLine />
                            </IconContext.Provider>
                            <dd className="ml-2 text-white text-md">
                                {t('contact.address')}
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
            <p className="text-sm text-center border-t-1"
            style={{borderColor: '#30436e', background: '#30436e', paddingBottom: '5px'}}
            >{t('contact.copyright')}</p>
        </div>
    )
}

export default Contact;
