import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";

import LanguageSelector from "./LanguageSelector";
import Logo from './Logo';
const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [activeMenuItem, setActiveMenuItem] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const {t} = useTranslation("common");

    const handleToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const scrollToSection = (id: any) => {
        const element = document.getElementById(id);
        if (element) {
            let yOffset = -112;
            if (window.innerWidth < 768) {
                yOffset = -300;
            }

            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        setIsMenuOpen(false);
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setIsMenuOpen(false);
        setActiveMenuItem('');
    };

    const handleMenuItemClick = (id: any) => {
        setActiveMenuItem(id);
        scrollToSection(id);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const sections = document.querySelectorAll('section');
        const options = {
            threshold: 0.6,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setActiveMenuItem(entry.target.id);
                }
            });
        }, options);

        sections.forEach(section => {
            observer.observe(section);
        });

        return () => {
            sections.forEach(section => {
                observer.unobserve(section);
            });
        };
    }, []);

    return (
        <header className="sticky top-0 bg-white border-gray-200 px-4 lg:px-6 dark:bg-gray-800 z-50">
            <nav className="bg-white border-gray-200 px-4 lg:px-6 lg:py-5 xs:py-3 dark:bg-gray-800">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <Logo />
                    <div className="flex items-center lg:order-2">
                        <LanguageSelector />
                        <button
                            onClick={handleToggle}
                            type="button"
                            className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                            aria-controls="mobile-menu-2"
                            aria-expanded={isMenuOpen}
                        >
                            <span className="sr-only">Open main menu</span>
                            {isMenuOpen ? (
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            ) : (
                                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                        clipRule="evenodd"
                                    ></path>
                                </svg>
                            )}
                        </button>
                    </div>
                    <div className={`justify-between items-center w-full lg:flex lg:w-auto lg:order-1 ${isMenuOpen ? '' : 'hidden'}`} id="mobile-menu-2">
                        <div className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                            <section onClick={scrollToTop} className={`block text-lg py-2 pr-4 pl-3 cursor-pointer text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 ${
                                activeMenuItem === '' ? 'text-primary-700 dark:text-white bg-primary-700 lg:bg-transparent lg:text-primary-700' : ''
                            }`}>{t('header.home')}</section>
                            <section onClick={() => handleMenuItemClick('about')}  className={`block text-lg py-2 pr-4 pl-3 cursor-pointer text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 ${
                                activeMenuItem === 'about' ? 'text-primary-700 dark:text-white bg-primary-700 lg:bg-transparent lg:text-primary-700' : ''
                            }`}>{t('header.about')}</section>
                            <section onClick={() => handleMenuItemClick('mission')}  className={`block text-lg py-2 pr-4 pl-3 cursor-pointer text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 ${
                                activeMenuItem === 'mission' ? 'text-primary-700 dark:text-white bg-primary-700 lg:bg-transparent lg:text-primary-700' : ''
                            }`}>{t('header.mission')}</section>
                            <section onClick={() => handleMenuItemClick('projects')}  className={`block text-lg py-2 pr-4 pl-3 cursor-pointer text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 ${
                                activeMenuItem === 'projects' ? 'text-primary-700 dark:text-white bg-primary-700 lg:bg-transparent lg:text-primary-700' : ''
                            }`}>{t('header.projects')}</section>
                            <section onClick={() => handleMenuItemClick('partners')}  className={`block text-lg py-2 pr-4 pl-3 cursor-pointer text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 ${
                                activeMenuItem === 'partners' ? 'text-primary-700 dark:text-white bg-primary-700 lg:bg-transparent lg:text-primary-700' : ''
                            }`}>{t('header.partners')}</section>
                            <section onClick={() => handleMenuItemClick('contact')}  className={`block text-lg py-2 pr-4 pl-3 cursor-pointer text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 ${
                                activeMenuItem === 'contact' ? 'text-primary-700 dark:text-white bg-primary-700 lg:bg-transparent lg:text-primary-700' : ''
                            }`}>{t('header.contact')}</section>
                        </div>
                    </div>
                </div>
                <button
                    type="button"
                    data-twe-ripple-init
                    data-twe-ripple-color="light"
                    className={`!fixed bottom-5 end-5 ${isVisible ? 'block' : 'hidden'} rounded-full bg-red-600 p-3 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg`}
                    id="btn-back-to-top"
                    onClick={scrollToTop}
                >
                <span className="[&>svg]:w-4">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="3"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18"
                        />
                    </svg>
                </span>
                </button>
            </nav>
        </header>
    );
};

export default Header;
