import React from 'react';
import { useTranslation } from "react-i18next";
import about_image from "../assets/about.jpg";

const About = () => {
    const { t } = useTranslation("common");

    return (
        <section
            id="about"
            className="min-h-screen flex flex-col lg:flex-row items-center justify-center sm:max-w-[1440px] 3xl:max-w-[1700px] mx-auto py-10 px-4 lg:py-20 lg:px-20"
        >
            {/* Image Section */}
            <div className="lg:w-1/2 w-full text-center lg:text-left">
                <img
                    src={about_image}
                    alt="about_image"
                    className="w-full h-auto max-w-full lg:max-h-[500px] object-cover rounded-md shadow-lg"
                />
            </div>

            {/* Text Section */}
            <div className="lg:w-1/2 w-full mt-6 lg:mt-0 lg:pl-10">
                <h2 className="my-4 font-bold sm:text-4xl text-2xl border-b-2 border-indigo-600 uppercase">
                    {t('about.tag')}
                </h2>
                <p className="text-gray-700 sm:text-xl text-md mt-4">
                    {t('about.info')}
                </p>
            </div>
        </section>
    );
};

export default About;