import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Image from './components/Image';
import About from './components/About';
import Services from './components/Services';
import Projects from './components/Projects';
import Contact from './components/Contact';
import OurPartners from './components/Partners';
import ProjectsPage from './pages/Projects';

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={
                        <>
                            <Header />
                            <Image />
                            <About />
                            <Services />
                            <Projects />
                            <OurPartners />
                            <Contact />
                        </>
                    } />
                    <Route path="/projects/:language" element={<ProjectsPage />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;