import React from 'react';
import image from '../assets/Armelsan_new.jpg';
import image1 from '../assets/dearsan-defence.png';
import image2 from '../assets/Gurdesan.png';
import image3 from '../assets/Shipyard.png';
import {useTranslation} from "react-i18next";

const OurPartners = () => {

    const {t} = useTranslation("common");

    return (
        <section id="partners" className="bg-gray-100">
            <div className="py-8 lg:py-16 mx-auto max-w-screen-xl px-4">
                <h1 className="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-black-900 dark:text-black md:text-4xl">
                    {t('partners.tag')}
                </h1>
                <div className="flex justify-center items-center">
                    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-8 w-full">
                        <div className="relative flex justify-center items-center bg-white"
                             style={{border: '3px solid #F3F4F6'}}>
                            <img src={image} alt="test2" className="w-full h-48 object-contain p-4 bg-white"
                                 style={{border: '3px solid #F3F4F6'}} />
                        </div>
                        <div className="relative flex justify-center items-center bg-white" style={{border: '3px solid #F3F4F6'}}>
                            <img src={image1} alt="test1" className="w-full h-48 object-contain p-4 bg-white"
                                 style={{border: '3px solid #F3F4F6'}} />
                        </div>
                        <div className="relative flex justify-center items-center bg-white" style={{border: '3px solid #F3F4F6'}}>
                            <img src={image2} alt="test" className="w-full h-48 object-contain p-4 bg-white"
                                 style={{border: '3px solid #F3F4F6'}} />
                        </div>
                        <div className="relative flex justify-center items-center bg-white" style={{border: '3px solid #F3F4F6'}}>
                            <img src={image3} alt="test3" className="w-full h-48 object-contain p-4 bg-white"
                                 style={{border: '3px solid #F3F4F6'}} />
                        </div>
                    </div>
                </div>
            </div>
        </section>


    )
}

export default OurPartners;