import { useTranslation } from "react-i18next";
import services_image from "../assets/Services-7.jpg";

const Services = () => {
    const { t } = useTranslation("common");

    return (
        <section id="mission" className="bg-gray-100 min-h-[92vh] flex flex-col">
            {/* Image Section */}
            <div className="relative w-full h-[30vh] sm:h-[50vh]">
                <img
                    className="w-full h-full opacity-70"
                    src={services_image}
                    alt="services_image"
                />
            </div>

            {/* Services Content */}
            <div className="px-4 sm:px-6 lg:px-16 my-2 sm:my-3 flex-grow 3xl:my-6">
                <h2 className="text-2xl sm:text-3xl font-extrabold text-gray-900 text-center">
                    {t('services.tag')}
                </h2>

                <div className="mt-2 sm:mt-3 grid grid-cols-1 gap-6 sm:gap-8 sm:grid-cols-2 lg:grid-cols-3 3xl:mt-12">
                    <div className="space-y-4">
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-1')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-2')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-11')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-4')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-8')}</p>
                    </div>

                    <div className="space-y-4">
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-6')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-7')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-5')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-9')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-10')}</p>
                    </div>

                    <div className="space-y-4">
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-3')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-12')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-13')}</p>
                        <p className="text-base sm:text-lg text-gray-700 red-circle">{t('services.list.content-14')}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Services;
