import React from 'react';
import {useTranslation} from "react-i18next";
import image from "../assets/HomePage_5.jpg";


const Image = () => {

    const {t} = useTranslation("common");

    return (
        <section className="relative mx-auto">
            <img className="w-full custom-height"
                 src={image} alt="test"/>
                <div className="absolute inset-0 bg-gray-700 opacity-50"></div>
                <div className="absolute inset-0 flex items-center justify-center flex-col w3-container w3-center w3-animate-bottom">
                  <h1 className="text-white text-5xl lg:text-7xl font-bold text-center">{t('slogan.text-1')}</h1>
                </div>
        </section>
    )
}

export default Image;