import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('az');
    const [t, i18n] = useTranslation('common');

    const handleToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleSelectLanguage = (language: any) => {
        setSelectedLanguage(language);
        setIsMenuOpen(false);
        i18n.changeLanguage(language);
    };

    const handleClickOutside = (event: any) => {
        if (event.target.closest('#languageButton') === null) {
            setIsMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-right">
            <button
                id="languageButton"
                onClick={handleToggle}
                className="flex items-center px-4 py-2 text-sm font-medium text-gray-400 hover:text-indigo-500 focus:outline-none"
            >
                <span>{selectedLanguage.toUpperCase()}</span>
                <svg
                    className="ml-2 h-5 w-5 text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
            {isMenuOpen && (
                <div
                    className="origin-top-right absolute right-0 mt-2 w-16 focus:outline-none z-50 bg-white ring-1 ring-black"
                    style={{border: '1px solid gray'}}
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                >
                    <div className="py-1 bg-gray-800" role="none">
                        <button
                            onClick={() => handleSelectLanguage('az')}
                            className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-blue-900"
                            role="menuitem"
                        >
                            {t('languages.az')}
                        </button>
                        <button
                            onClick={() => handleSelectLanguage('en')}
                            className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-blue-900"
                            role="menuitem"
                        >
                            {t('languages.en')}
                        </button>
                        <button
                            onClick={() => handleSelectLanguage('ru')}
                            className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-blue-900"
                            role="menuitem"
                        >
                            {t('languages.ru')}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LanguageSelector;
