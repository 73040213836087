import React from 'react';
import image from "../assets/Ships logo PNG.png";

const Logo = () => {
    return (
        <a href="/" className="flex items-center">
            <img
                src={image}
                className="h-12 sm:h-16 my-2 scale-125 sm:scale-150"
                alt="Flowbite Logo"
            />
        </a>
    );
};

export default Logo;